import type { DropResult } from "@hello-pangea/dnd";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import clsx from "clsx";
import _ from "lodash";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { useFetcher } from "react-router";
import Badge from "~/components/badge";
import { IconAttachment } from "~/components/icons";
import CardForm from "~/components/kanban/form";
import KanbanTitle, { getKanbanTitle } from "~/components/kanban/title";
import Link from "~/components/link";
import ProjectLink from "~/components/projects/link";
import { canAccessProject } from "~/components/projects/team";
import { fetcherSucceeded } from "~/components/remix-form";
import Cell from "~/components/table-cell";
import Row from "~/components/table-row";
import type {
  ContentTopicListFieldsFragment,
  KanbanCardFieldsFragment
} from "~/types/api";
import { useCurrentUser } from "~/utils/auth";
import { formatDate, isPastDue } from "~/utils/dates";
import { formatNumber, plural } from "~/utils/formatting";

type Props = {
  cards: (KanbanCardFieldsFragment | ContentTopicListFieldsFragment)[];
  filter?: string;
  backlog?: boolean;
  hideParent?: boolean;
  mode?: "KanbanCard" | "ContentTopic";
};

const SORTED_COLUMNS = [
  "Today",
  "Scheduled",
  "Urgent",
  "Next",
  "Should Do",
  "Could Do",
  "Done"
];

const getParentSort = (
  card: KanbanCardFieldsFragment | ContentTopicListFieldsFragment
) => {
  if (card.__typename === "ContentTopic") {
    return card.title;
  }
  if (card.project) {
    return card.project.number;
  }
  if (card.feature) {
    return "Bug/Feature Request";
  }
  if (card.contentTopic) {
    return card.contentTopic.title;
  }
  return "N/A";
};

export default function KanbanList({
  cards: sourceCards,
  filter,
  backlog,
  hideParent,
  mode = "KanbanCard"
}: Props) {
  const [sort, setSort] = useState("column");
  const [cards, setCards] = useState(
    _.sortBy(sourceCards, (c) =>
      c.__typename === "ContentTopic" ? c.statusRank : c.rank
    )
  );
  const [dragColumn, setDragColumn] = useState("");
  const fetcher = useFetcher<unknown>();
  const initialRender = useRef(true);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (fetcherSucceeded(fetcher)) {
      setCards(
        _.sortBy(sourceCards, (c) =>
          c.__typename === "KanbanCard" ? c.rank : c.statusRank
        )
      );
    }
  }, [fetcher.state, sourceCards]);

  useEffect(() => {
    if (!initialRender.current) {
      setCards(
        _.sortBy(sourceCards, (c) =>
          c.__typename === "KanbanCard" ? c.rank : c.statusRank
        )
      );
    }
    initialRender.current = false;
  }, [sourceCards]);

  const filtered = useMemo(
    () =>
      cards.filter(
        (card) =>
          (!filter ||
            (card.__typename === "KanbanCard"
              ? `${card.project?.number || ""} ${card.project?.name || ""} ${
                  card.deliverable?.description || ""
                } ${card.feature?.title || ""} ${card.title || ""}`
                  .toLocaleLowerCase()
                  .includes(filter.toLocaleLowerCase())
              : card.title
                  .toLocaleLowerCase()
                  .includes(filter.toLocaleLowerCase()))) &&
          (backlog
            ? card.__typename === "ContentTopic"
              ? card.status === "Backlog"
              : card.column === "Backlog"
            : card.__typename === "KanbanCard"
              ? card.column !== "Backlog"
              : card.status !== "Backlog")
      ),
    [backlog, cards, filter]
  );

  const sorted = useMemo(
    () =>
      _.sortBy(
        filtered,
        sort === "description"
          ? (c) => getKanbanTitle(c, "list")
          : sort === "parent"
            ? (c) => getParentSort(c)
            : sort === "column" && mode === "KanbanCard"
              ? "rank"
              : sort,
        (c) =>
          SORTED_COLUMNS.indexOf(
            c.__typename === "KanbanCard" ? c.column : c.status
          ),
        "statusRank"
      ),
    [filtered, sort, mode]
  );

  const grouped = _.groupBy(sorted, (c) =>
    c.__typename === "KanbanCard" ? c.column : c.status
  );

  const onDragEnd = (result: DropResult) => {
    if (
      result.destination &&
      (result.destination.droppableId !== result.source.droppableId ||
        result.destination.index !== result.source.index)
    ) {
      const column = result.destination?.droppableId;
      const card = cards.find((c) => c.id === result.draggableId);

      if (card) {
        const columnCards = cards.filter(
          (c) =>
            (c.__typename === "KanbanCard"
              ? c.column === column
              : c.status === column) && c.id !== card.id
        );

        let nextColumnCards = [...columnCards];
        const next =
          card.__typename === "KanbanCard"
            ? {
                ...card,
                column: result.destination.droppableId,
                dateCompleted: formatDate(new Date(), { format: "YYYY-MM-DD" })
              }
            : { ...card, status: result.destination.droppableId };

        if (column === "Done") {
          nextColumnCards.splice(0, 0, next);
        } else {
          nextColumnCards.splice(result.destination.index, 0, next);
        }
        nextColumnCards = nextColumnCards.map((card, i) => ({
          ...card,
          [card.__typename === "ContentTopic" ? "statusRank" : "rank"]: i
        }));
        flushSync(() =>
          setCards([
            ...cards.filter(
              (c) =>
                (c.__typename === "KanbanCard"
                  ? c.column !== column
                  : c.status !== column) && c.id !== card.id
            ),
            ...nextColumnCards
          ])
        );
        if (card.__typename === "KanbanCard") {
          fetcher.submit(
            {
              id: card.id,
              column,
              position:
                result.destination.index === columnCards.length ? "last" : "",
              rank:
                result.destination.index === columnCards.length
                  ? ""
                  : columnCards[result.destination.index].rank?.toString()
            },
            {
              action: "/resources/kanban/sort",
              method: "post"
            }
          );
        } else if (card.__typename === "ContentTopic") {
          const fd = new FormData();
          fd.set("id", card.id);
          fd.set("status", column);
          if (result.destination.index === columnCards.length) {
            fd.set("statusPosition", "last");
          } else {
            fd.set(
              "statusRank",
              (
                columnCards[
                  result.destination.index
                ] as ContentTopicListFieldsFragment
              ).statusRank.toString()
            );
          }
          fetcher.submit(fd, {
            action: "/resources/content/sort",
            method: "post"
          });
        }
      }
    }
  };

  let nextNumber = 1;
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={(item) => setDragColumn(item.source.droppableId)}
    >
      <Row header>
        <Cell size="40" className="text-center">
          #
        </Cell>
        {mode === "ContentTopic" ? (
          <>
            {!hideParent && <Cell>Topic</Cell>}
            <Cell size="220">Category</Cell>
          </>
        ) : (
          <>
            {!hideParent && (
              <Cell
                onClick={() => setSort("parent")}
                className="cursor-pointer"
              >
                Project
              </Cell>
            )}
            <Cell
              onClick={() => setSort("description")}
              className="cursor-pointer"
            >
              Description
            </Cell>
            {sort !== "column" && (
              <Cell
                size="120"
                className="cursor-pointer text-center"
                onClick={() => setSort("column")}
              >
                Column
              </Cell>
            )}
            <Cell size="120" className="text-center">
              Who
            </Cell>
            <Cell
              onClick={() => setSort("dueDate")}
              size="100"
              className="cursor-pointer text-center"
            >
              Due
            </Cell>
            <Cell size="70" className="text-center">
              Hours
            </Cell>
          </>
        )}
      </Row>

      {sort === "column"
        ? (backlog ? ["Backlog"] : SORTED_COLUMNS).map((column) => {
            if (
              ["Could Do", "Should Do", "Urgent"].includes(column) &&
              !grouped[column]?.length
            ) {
              return null;
            }
            let cards = grouped[column] || [];
            cards =
              column === "Done" && mode === "KanbanCard"
                ? _.orderBy(cards, "completedAt", "desc")
                : cards;
            const localNumber = nextNumber;
            nextNumber = localNumber + (grouped[column]?.length || 0) + 1;
            return (
              <Fragment key={column}>
                <Row className="info font-bold">
                  <Cell size="40" className="text-center">
                    {localNumber}
                  </Cell>
                  <Cell>{column}</Cell>
                  <Cell>
                    {grouped[column]
                      ? plural(
                          mode === "ContentTopic" ? "Topic" : "Card",
                          grouped[column].length,
                          true
                        )
                      : `No ${mode === "ContentTopic" ? "Topics" : "Cards"}`}
                  </Cell>
                  {mode === "KanbanCard" && (
                    <>
                      <Cell size="220" />
                      <Cell size="70" className="text-center">
                        {formatNumber(
                          _.sumBy(grouped[column] || [], (c) =>
                            Number.parseFloat(
                              (c as KanbanCardFieldsFragment).hours || "0"
                            )
                          )
                        )}
                      </Cell>
                    </>
                  )}
                </Row>
                {mode === "KanbanCard" && column === "Done" ? (
                  <Droppable
                    droppableId={column}
                    isDropDisabled={dragColumn === "Done-sorted"}
                  >
                    {(dropProvided, dropSnapshot) => (
                      <div
                        ref={dropProvided.innerRef}
                        {...dropProvided.droppableProps}
                      >
                        {dropProvided.placeholder}
                        <Droppable
                          droppableId={`${column}-sorted`}
                          isDropDisabled
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              key={column}
                              {...provided.droppableProps}
                            >
                              {cards.map((card, index) => (
                                <CardRow
                                  card={card}
                                  key={card.id}
                                  hideParent={hideParent}
                                  index={index}
                                  rowNumber={index + localNumber + 1}
                                />
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    )}
                  </Droppable>
                ) : (
                  <Droppable droppableId={column}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        key={column}
                        {...provided.droppableProps}
                      >
                        {cards.map((card, index) => (
                          <CardRow
                            card={card}
                            key={card.id}
                            hideParent={hideParent}
                            index={index}
                            rowNumber={index + localNumber + 1}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                )}
              </Fragment>
            );
          })
        : sorted.map((card, i) => (
            <CardRow
              card={card}
              key={card.id}
              hideParent={hideParent}
              index={i}
              showColumn
              rowNumber={i + 1}
              draggable={false}
            />
          ))}
    </DragDropContext>
  );
}

type RowProps = {
  card: KanbanCardFieldsFragment | ContentTopicListFieldsFragment;
  index: number;
  hideParent?: boolean;
  showColumn?: boolean;
  rowNumber: number;
  draggable?: boolean;
};

const CardRow = ({
  card,
  hideParent,
  index,
  rowNumber,
  draggable = true,
  showColumn
}: RowProps) => {
  const [editing, setEditing] = useState(false);
  const currentUser = useCurrentUser();
  const canView =
    card.__typename === "ContentTopic" || canAccessProject(currentUser, card);

  const cells = useMemo(
    () => (
      <>
        <Cell size="40" className="text-center">
          {rowNumber}
        </Cell>
        {card.__typename === "ContentTopic" ? (
          <>
            {!hideParent && (
              <Cell>
                <Link to={`/content/${card.id}`}>{card.title}</Link>
              </Cell>
            )}
            <Cell size="220">{card.category}</Cell>
          </>
        ) : (
          <>
            {!hideParent && (
              <Cell>
                {card.project ? (
                  <ProjectLink project={card.project} />
                ) : card.feature ? (
                  <Link to={`/features/${card.feature.id}`}>
                    Bug/Feature Request
                  </Link>
                ) : card.contentTopic ? (
                  <Link to={`/content/${card.contentTopic.id}`}>
                    {card.contentTopic.title}
                  </Link>
                ) : (
                  "N/A"
                )}
              </Cell>
            )}
            <Cell>
              <div className="flex justify-between space-x-4">
                <span className="flex-1">
                  <Link to={() => setEditing(true)}>
                    <KanbanTitle
                      card={card}
                      format="list"
                      canView={canView}
                      onEdit={() => setEditing(true)}
                    />
                  </Link>
                </span>
                {card.blocked && (
                  <span>
                    <Badge mode="danger">Blocked</Badge>
                  </span>
                )}
                {card.uploads.length > 0 && <IconAttachment />}
                {editing && (
                  <CardForm id={card.id} onClose={() => setEditing(false)} />
                )}
              </div>
            </Cell>
            {showColumn && (
              <Cell size="120" className="text-center">
                {card.column}
              </Cell>
            )}
            <Cell size="120" className="whitespace-nowrap text-center">
              {card.user?.fullname}
            </Cell>
            <Cell
              size="100"
              className={clsx(
                "text-center",
                isPastDue(card.dueDate) && "text-danger danger font-bold"
              )}
            >
              {card.dueDate ? formatDate(card.dueDate) : "-"}
            </Cell>
            <Cell size="70" className="text-center">
              {formatNumber(card.hours || 0)}
            </Cell>
          </>
        )}
      </>
    ),
    [canView, card, editing, hideParent, rowNumber, showColumn]
  );

  return draggable ? (
    <Draggable draggableId={card.id} index={index}>
      {(provided, snapshot) => (
        <Row
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {cells}
        </Row>
      )}
    </Draggable>
  ) : (
    <Row>{cells}</Row>
  );
};
